import { Container } from "react-bootstrap";

const Privacy = () => {
  return (
    <>
      <section className="py-3">
        <Container>
          <h1>Privacy policy:</h1>
          <p>
            Welcome to Meat Market! We are committed to protecting your privacy
            and ensuring a secure online experience. This Privacy Policy
            explains how we collect, use, and safeguard your personal
            information. By using our website, you agree to the practices
            described in this policy.
          </p>
          <h3>Information We Collect:</h3>
          <ul>
            <li>
              <h4>Personal Information:</h4>
              <p>
                When you make a purchase or create an account, we collect
                personal information such as your name, email address, phone
                number, shipping address, and payment details.
              </p>
            </li>
            <li>
              <h4>Usage Data:</h4>
              <p>
                We collect information about your interaction with our website,
                including your IP address, browser type, pages visited, and the
                dates and times of your visits.
              </p>
            </li>
            <li>
              <h4>Cookies and Tracking Technologies:</h4>
              <p>
                We use cookies and similar technologies to enhance your browsing
                experience, analyze website traffic, and personalize content.
                You can manage your cookie preferences through your browser
                settings.
              </p>
            </li>
          </ul>
          <h3>How We Use Your Information:</h3>
          <ul>
            <li>
              <h4>Order Processing:</h4>
              <p>
                We use your personal information to process and fulfill your
                orders, including sending you order confirmations, updates, and
                delivery notifications.
              </p>
            </li>
            <li>
              <h4>Customer Support:</h4>
              <p>
                Your information helps us respond to your inquiries, provide
                customer support, and improve our services.
              </p>
            </li>
            <li>
              <h4>Marketing:</h4>
              <p>
                With your consent, we may use your email address to send you
                promotional materials, special offers, and newsletters. You can
                opt out of these communications at any time.
              </p>
            </li>
            <li>
              <h4>Analytics:</h4>
              <p>
                We analyze usage data to understand how users interact with our
                website, which helps us improve our services and enhance user
                experience.
              </p>
            </li>
          </ul>
          <h3>How We Share Your Information:</h3>
          <ul>
            <li>
              <h4>Third-Party Service Providers:</h4>
              <p>
                We may share your personal information with third-party service
                providers who assist us in operating our website, processing
                payments, and delivering orders. These providers are
                contractually obligated to protect your information and use it
                only for the purposes we specify.
              </p>
            </li>
            <li>
              <h4>Legal Requirements: </h4>
              <p>
                We may disclose your information if required to do so by law or
                in response to legal requests or obligations.
              </p>
            </li>
            <li>
              <h4>Business Transfers:</h4>
              <p>
                In the event of a merger, acquisition, or sale of our business,
                your information may be transferred to the acquiring entity.
              </p>
            </li>
          </ul>
          <h3>Data Security</h3>
          <ul>
            <li>
              <p>
                We implement a variety of security measures to protect your
                personal information from unauthorized access, alteration,
                disclosure, or destruction. While we strive to use commercially
                acceptable means to protect your information, no method of
                transmission over the Internet or electronic storage is 100%
                secure.
              </p>
            </li>
          </ul>
          <h3>Your Rights</h3>
          <ul>
            <li>
              <h4>Access and Correction:</h4>
              <p>
                You have the right to access, correct, or update your personal
                information by logging into your account or contacting us
                directly.
              </p>
            </li>
            <li>
              <h4>Opt-Out:</h4>
              <p>
                You can opt out of receiving marketing communications from us by
                following the unsubscribe instructions in those communications.
              </p>
            </li>
            <li>
              <h4>Deletion:</h4>
              <p>
                {" "}
                You may request the deletion of your personal information by
                contacting us. Please note that we may need to retain certain
                information for legal or operational purposes.
              </p>
            </li>
          </ul>
          <h3>Changes to This Privacy Policy:</h3>
          <ul>
            <li>
              <p>
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated effective date. We
                encourage you to review this policy periodically to stay
                informed about how we are protecting your information.
              </p>
            </li>
          </ul>
          <p>
            Thank you for choosing Meat Market. Your trust is important to us,
            and we are committed to safeguarding your privacy.
          </p>
        </Container>
      </section>
    </>
  );
};

export default Privacy;
