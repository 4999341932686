import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Rating } from "@mui/material";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { getReviews } from "../Providers/Api_Calls";

interface User {
  _id: string;
  name: string;
  email: string;
  phone: number;
}

interface Review {
  _id: string;
  product: string | null;
  user: User;
  rating: number;
  comment: string;
  date: string;
  isDeleted: boolean;
}

const ReviewCarousel: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    autoplay: true,
  };

  const getRating = async () => {
    try {
      const reviewResp = await getReviews(ApiEndPoints("getRating"));
      if (reviewResp && reviewResp.reviews) {
        const filteredReviews = reviewResp.reviews
          .filter((review: Review) => review.rating >= 4.5)
          .slice(0, 10);
        console.log(filteredReviews, "filteredReviews");

        setReviews(filteredReviews);
      } else {
        console.error("No reviews found in the response.");
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    getRating();
  }, []);

  return (
    <>
      <section
        className="quality-bannerimg py-4 mb-4"
        style={{ overflow: "hidden" }}
      >
        <Container>
          <div className="row py-3">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src="/assets/vitamin photo.webp"
                  style={{ width: "297px" }}
                  alt="Quality Meat"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="qualityBanner">
                <h2 className="title">Quality Meat</h2>
                <p>
                  THE <span>BEST MEAT</span> IN YOUR CITY
                </p>
                <p>
                  Welcome to Meat Market, where we offer the finest selection of
                  chicken and mutton sourced from trusted local farms. Our
                  commitment to quality and freshness ensures that every cut of
                  meat meets the highest standards, providing you with delicious
                  and nutritious options for all your culinary needs. With a
                  focus on ethical sourcing, hygiene, and personalized customer
                  service, Meat Market stands out as the best destination for
                  premium meat products.Buy with our online chicken and mutton delivery!
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section
        className="reviewBanner py-4 mb-5 mt-5"
        style={{ overflow: "hidden" }}
      >
        <Container>
          <div className="row">
            <h2 className="title text-center py-4">“What Our Customer Says”</h2>
            <div className="slider-container">
              <Slider {...settings}>
                {reviews.map((review, i) => (
                  <div className="pb-5" key={i}>
                    <div>
                      <div className="col-lg-6 d-flex justify-content-center align-items-center mx-auto review-white">
                        <div className="review-box">
                          <p>{review.comment}</p>
                          <img
                            className="img-fluid mx-auto"
                            src="/assets/user.png"
                            alt="Reviewer"
                            loading="lazy"
                          />
                          <h6 className="text-center pt-2">
                            {review.user.name}
                          </h6>
                          <div className="text-center">
                            <Rating
                              name="read-only"
                              value={review.rating}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ReviewCarousel;
