import { Avatar, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { GoDotFill } from "react-icons/go";
import { IoIosStar, IoMdCloseCircleOutline } from "react-icons/io";
import "./Orders.css";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import {
  cancelOrders,
  getMe,
  handlePostRating,
} from "../Providers/Api_Calls";
import moment from "moment";
import { API_URL } from "../Config";
import { message, Spin } from "antd";
import axios from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
};

type Order = {
  _id: string;
  orderId: string;
  createdAt: string;
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  paymentStatus: string;
  paymentAmount: number | null;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string | null;
  refundStatus: string;
  isDeleted: boolean;
  lineItems: any[];
  __v: number;
  cancellationDate: string | null;
  deliveryDate: string | null;
};

const Orders = () => {
  const [open, setOpen] = React.useState(false);
  const [openrating, setOpenrating] = React.useState(false);
  const [ratingData, setRatingData] = useState({
    product: "",
    user: "",
    rating: 0,
    comment: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const [id, setId] = useState("");

  const handleOpen = (e: any) => {
    setId(e);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleOpenRating = (data: any) => {
    setRatingData({
      ...ratingData,
      product: data.lineItems[0].product,
      user: userData._id,
    });
    setOpenrating(true);
  };

  const handleCloseRating = () => setOpenrating(false);

  const handlepostRating = async () => {
    try {
      await handlePostRating(ratingData.product, ratingData);
      successmessage("Thanks for your rating");
      handleCloseRating();
    } catch (error) {
      errormessage("Something went wrong");
    }
  };

  const navigate = useNavigate();
  const handleNavOrderDetails = (id: any) => {
    console.log(id, "id");

    navigate(`/order-detail/${id}`);
  };

  const [userData, setUserData] = useState<any>(null);
  const [deliveredOrders, setDeliveredOrders] = useState<Order[]>([]);
  const [orderedOrders, setOrderedOrders] = useState<Order[]>([]);
  const [cancelledOrders, setCancelledOrders] = useState<Order[]>([]);

  const [canceldata, setcanceldata] = useState({
    cancellationReason: "",
    isCancelled: true,
    cancellationDate: new Date(),
  });

  const formatDate = (dateString: any) => {
    return moment(dateString).format("ddd, Do MMM");
  };

  useEffect(() => {
    let authtoken = localStorage.getItem("authtoken");
    if (authtoken) {
      getUser();
    }
  }, [canceldata]);

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeuser.user);
      const orderIds = getMeuser.user.orders;
      fetchOrders(getMeuser.user._id);
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchOrders = async (orderIds: string[]) => {
  //   try {
  //     const delivered: Order[] = [];
  //     const ordered: Order[] = [];
  //     const cancelled: Order[] = [];
  //     for (const orderId of orderIds) {
  //       const orderDetail = await getAllorders(
  //         ApiEndPoints("myOrders"),
  //         orderId
  //       );
  //       const order = orderDetail.order;

  //       // Categorize orders based on their status
  //       if (order.isCancelled) {
  //         cancelled.push(order);
  //       } else if (order.status === "completed") {
  //         delivered.push(order);
  //       } else if (
  //         order.status.toLowerCase() === "ordered" ||
  //         order.status === "out for delivery" ||
  //         order.status === "pending"
  //       ) {
  //         ordered.push(order);
  //       }
  //     }

  //     // Set state for each category
  //     setDeliveredOrders(delivered);
  //     setOrderedOrders(ordered);
  //     setCancelledOrders(cancelled);
  //   } catch (error) {
  //     console.error("Error fetching orders:", error);
  //     errormessage("Something went wrong!")
  //   }
  // };

  const [isLoading, setIsLoading] = useState(false);
  const fetchOrders = async (orderIds: string[]) => {
    try {
console.log(userData,"userData");


      const res=await axios.get(`${API_URL}/api/order?user=${orderIds}`)
      console.log(res.data.orders,"res");
      

      // Fetch all order details in parallel
      // const orders = await Promise.all(
      //   orderIds.map(async (orderId) => {
      //     const orderDetail = await getAllorders(
      //       ApiEndPoints("myOrders"),
      //       orderId
      //     );
      //     return orderDetail.order;
      //   })
      // );

      // Categorize orders based on their status
      const delivered: Order[] = [];
      const ordered: Order[] = [];
      const cancelled: Order[] = [];

      res.data.orders.forEach((order:any) => {
        if (order.isCancelled) {
          cancelled.push(order);
        } else if (order.status === "completed") {
          delivered.push(order);
        } else if (
          order.status.toLowerCase() === "ordered" ||
          order.status === "out for delivery" ||
          order.status === "pending"
        ) {
          ordered.push(order);
        }
      });

      // Set state for each category
      setDeliveredOrders(delivered);
      setOrderedOrders(ordered);
      setCancelledOrders(cancelled);
      if (
        deliveredOrders.length ||
        orderedOrders.length ||
        cancelledOrders.length
      ) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      errormessage("Something went wrong!");
    }
  };

  const handleCancelitem = async () => {
    try {
      await cancelOrders(
        ApiEndPoints("cancelproduct"),
        id,
        canceldata
      );
      successmessage("Cancellation success");
      getUser();
      setOpen(false);
      setcanceldata({
        cancellationReason: "",
        isCancelled: true,
        cancellationDate: new Date(),
      });
    } catch (err) {
      console.log(err);
      errormessage(err);
    }
  };

  const handleRatingChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | null
  ) => {
    setRatingData({
      ...ratingData,
      rating: newValue ?? 0,
    });
  };

  const handlenavCancel = (id: any) => {
    navigate(`/cancelled-order/${id}`);
  };

  const handlenavDeliverddetails = (id: any) => {
    navigate(`/delivered-detail/${id}`);
  };

  return (
    <>
      {contextHolder}
      <section className="my-3 pt-4">
        <Container>
          <p className="nav-all pb-3">
            <Link to="/">Home</Link> {">"}
            <span>My Orders</span>
          </p>{" "}
        </Container>
      </section>
      <div>
        {orderedOrders.length ||
        deliveredOrders.length ||
        cancelledOrders.length ? (
          <>
            <>
              <section className="my-3">
                <Container>
                  {orderedOrders.map((order, index) => (
                    <div
                      key={order._id || index}
                      className="row mx-0 my-2 "
                      style={{ backgroundColor: "#F4F4F4", cursor: "pointer" }}
                    >
                      <div
                        className="col-lg-4 col-xl-4 col-sm-12 col-md-12 px-0"
                        onClick={() => handleNavOrderDetails(order._id)}
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <Avatar
                              className="avatarOrder"
                              alt="Remy Sharp"
                              src={
                                order.lineItems &&
                                order.lineItems[0] &&
                                order.lineItems[0].product &&
                                order.lineItems[0].product.image
                                  ? `${API_URL}/images/${order.lineItems[0].product.image}`
                                  : "Name not available"
                              }
                            />
                          </div>
                          <div style={{ marginLeft: "2rem" }}>
                            <p className="mb-0 order-itemname">
                              {order.lineItems && order.lineItems.length > 0
                                ? order.lineItems
                                    .map((val, i) =>
                                      val.product && val.product.name
                                        ? val.product.name
                                        : "Unknown product"
                                    )
                                    .join(", ")
                                : "No products available"}{" "}
                            </p>
                            <p className="mb-0" style={{ color: "#454545" }}>
                              {order.lineItems && order.lineItems.length > 0
                                ? order.lineItems
                                    .map((val, i) => val.quantity)
                                    .join(", ")
                                : "No quantity available"}{" "}
                              gm
                            </p>
                            <p className="mb-0" style={{ fontWeight: 600 }}>
                              ₹{" "}
                              {(order.paymentAmount &&
                                order.paymentAmount.toFixed(2)) ||
                                "Price"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-xl-8 col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
                        <div className="Wm9vLv sm-align">
                          <div className="q4l-J2" style={{ width: "33.333%" }}>
                            <div className="YrA9Sn">
                              <div
                                className={
                                  order.status === "ordered" ||
                                  order.status === "pending"
                                    ? "SfJXc8O"
                                    : "SfJXc8O-incomplete"
                                }
                              >
                                <span>Order Confirmed</span>
                              </div>
                              <div className="GS3O1E T7AO1y sDEgvV"></div>
                              <div
                                className={
                                  order.status === "out for delivery"
                                    ? "_9MhawTW"
                                    : "_9MhawT"
                                }
                              >
                                <div
                                  className={
                                    order.status.toLowerCase() ===
                                    "outfordelivery"
                                      ? "_9XeNR9"
                                      : "_9XeNR9-incomplete"
                                  }
                                ></div>
                              </div>
                              <div className="SfJXc8">
                                <span>{formatDate(order.createdAt)}</span>
                              </div>
                            </div>
                          </div>
                          {/* Add other status indicators here if needed */}
                          <div className="q4l-J2" style={{ width: "33.333%" }}>
                            <div className="YrA9Sn">
                              <div
                                className={
                                  order.status.toLowerCase().trim() ===
                                  "outfordelivery"
                                    ? "SfJXc8O"
                                    : "SfJXc8O-incomplete"
                                }
                              >
                                <span className="mobie-del">
                                  Out For Delivery
                                </span>
                              </div>
                              <div
                                className={`GS3O1E  sDEgvV ${
                                  order.status === "out for delivery"
                                    ? "delivery"
                                    : "T7AO1y-incomplete"
                                }`}
                              ></div>
                              <div className="_9MhawT">
                                <div
                                  className={
                                    order.status.toLocaleLowerCase().trim() ===
                                    "Outfordelivery"
                                      ? "_9XeNR9"
                                      : "_9XeNR9-incomplete"
                                  }
                                ></div>
                              </div>
                              <div className="SfJXc8"></div>
                            </div>
                          </div>
                          <div className="q4l-J2" style={{ width: "33.333%" }}>
                            <div className="YrA9Sn">
                              <div
                                className={
                                  order.status.toLowerCase().trim() ===
                                  "completed"
                                    ? "SfJXc8O"
                                    : "SfJXc8O-incomplete"
                                }
                              >
                                <span>Delivery</span>
                              </div>
                              <div
                                className={`GS3O1E  sDEgvV ${
                                  order.status === "completed"
                                    ? "delivery"
                                    : "T7AO1y-incomplete"
                                }`}
                              ></div>
                              <div className="SfJXc8-incomplete">
                                <span>{formatDate(order.deliveryDate)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg-cancel">
                          <a
                            className="cancel-Order"
                            onClick={() => handleOpen(order._id)}
                          >
                            <i className="fi fi-rr-circle-xmark d-flex pe-2" />
                            <span style={{ paddingBottom: "4px" }}>cancel</span>
                          </a>
                        </div>
                      </div>
                      <div className="sm-cancel">
                        <a
                          className="cancel-Order justify-content-end"
                          onClick={() => handleOpen(order._id)}
                        >
                          <i className="fi fi-rr-circle-xmark d-flex pe-2" />
                          <span style={{ paddingBottom: "4px" }}>cancel</span>
                        </a>
                      </div>
                    </div>
                  ))}
                </Container>
              </section>
              <div>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Cancellation</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handleClose}
                        />
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="row cancel-box">
                        <div className="col-lg-5">
                          <div>
                            <h4>Reason For Cancellation:</h4>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div>
                            <textarea
                              rows={3}
                              onChange={(e) =>
                                setcanceldata({
                                  ...canceldata,
                                  cancellationReason: e.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="text-center py-2 confrim-btn">
                        <Button variant="contained" onClick={handleCancelitem}>
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
            </>
            <section className="my-3">
              <Container>
                {deliveredOrders.map((order, index) => (
                  <div
                    className="row my-2 mx-0 sm-hide"
                    style={{ backgroundColor: "#F4F4F4", cursor: "pointer" }}
                    key={order._id || index}
                  >
                    <div
                      className="col-lg-4 col-xl-4 col-sm-12 col-md-4 px-0"
                      onClick={() => handlenavDeliverddetails(order._id)}
                    >
                      <div className="d-flex justify-content-start align-items-center sm-avatar">
                        <div>
                          <Avatar
                            className="avatarOrder"
                            alt="Product Image"
                            src={
                              order.lineItems &&
                              order.lineItems[0] &&
                              order.lineItems[0].product &&
                              order.lineItems[0].product.image
                                ? `${API_URL}/images/${order.lineItems[0].product.image}`
                                : "Name not available"
                            }
                          />
                        </div>
                        <div style={{ marginLeft: "2rem" }}>
                          <p className="mb-0 order-itemname">
                            {order.lineItems && order.lineItems.length > 0
                              ? order.lineItems
                                  .map((val, i) =>
                                    val.product && val.product.name
                                      ? val.product.name
                                      : "Unknown product"
                                  )
                                  .join(", ")
                              : "No products available"}
                          </p>
                          <p className="mb-0">
                            {order.lineItems && order.lineItems.length > 0
                              ? order.lineItems
                                  .map((val, i) => val.quantity)
                                  .join(", ")
                              : "No quantity available"}{" "}
                            gm
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 amountHide-sm col-xl-4 col-sm-6 col-6 col-md-4 px-0 d-flex justify-content-center align-items-center"
                      onClick={() => handlenavDeliverddetails(order._id)}
                    >
                      <div>
                        <p className="mb-0" style={{ fontWeight: 600 }}>
                          ₹ {order.paymentAmount?.toFixed() || "Price"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-12 col-12 col-md-4 px-0 d-flex justify-content-end align-items-center">
                      <div style={{ padding: "0px 12px" }}>
                        <p
                          className="mb-2 d-flex justify-content-start align-items-center"
                          style={{ fontWeight: 700 }}
                        >
                          <GoDotFill style={{ color: "#0D354F" }} />
                          {order.deliveryDate
                            ? `Delivery On ${formatDate(order.deliveryDate)}`
                            : "Delivery Date"}
                        </p>
                        <p
                          className="mb-2"
                          style={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          Your item has been delivered
                        </p>
                        <p
                          className="mb-0 d-flex justify-content-start align-items-center"
                          style={{
                            color: "#474747",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                          // onClick={() => handleOpenRating({ product: order.lineItems[0]._id })}
                          onClick={() => handleOpenRating(order)}
                        >
                          <IoIosStar style={{ color: "#0D354F" }} />
                          Rate & Review Product
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Container>
            </section>
            <section className="my-3">
              <Container>
                {cancelledOrders.map((order, index) => (
                  <div
                    className="row my-2 mx-0 "
                    style={{ backgroundColor: "#F4F4F4", cursor: "pointer" }}
                    onClick={() => handlenavCancel(order._id)}
                  >
                    <div
                      className="row mx-0 px-0 "
                      style={{ backgroundColor: "#F4F4F4", overflow: "hidden" }}
                      key={order._id || index}
                    >
                      <div className="col-lg-4 col-xl-4 col-sm-12 col-md-4 px-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div>
                            <Avatar
                              className="avatarOrder"
                              alt="Product Image"
                              src={
                                order.lineItems &&
                                order.lineItems[0] &&
                                order.lineItems[0].product &&
                                order.lineItems[0].product.image
                                  ? `${API_URL}/images/${order.lineItems[0].product.image}`
                                  : "Name not available"
                              }
                            />
                          </div>
                          <div style={{ marginLeft: "2rem" }}>
                            <p className="mb-0 order-itemname">
                              {order.lineItems && order.lineItems.length > 0
                                ? order.lineItems
                                    .map((val, i) =>
                                      val.product && val.product.name
                                        ? val.product.name
                                        : "Unknown product"
                                    )
                                    .join(", ")
                                : "No products available"}
                            </p>
                            <p className="mb-0" style={{ color: "#454545" }}>
                              {order.lineItems && order.lineItems.length > 0
                                ? order.lineItems
                                    .map((val, i) => val.quantity)
                                    .join(", ")
                                : "No quantity available"}
                              gm
                            </p>
                            <p className="mb-0" style={{ fontWeight: 600 }}>
                              ₹ {order.paymentAmount?.toFixed() || "Price"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-xl-5 col-sm-12 col-md-5 d-flex justify-content-center align-items-center">
                        <div style={{ width: "100%" }}>
                          <div className="Wm9vLv" style={{ width: "100%" }}>
                            <div className="q4l-J2" style={{ width: "100%" }}>
                              <div className="YrA9Sn">
                                <div
                                  className={
                                    order.status === "confirmed"
                                      ? "SfJXc8O"
                                      : "SfJXc8O-incomplete"
                                  }
                                >
                                  <span>Order Confirmed</span>
                                </div>
                                <div className="GS3O1E T7AO1y sDEgvV"></div>
                                <div
                                  className={
                                    order.isCancelled
                                      ? "_9XeNR9C"
                                      : "_9XeNR9-incomplete"
                                  }
                                ></div>
                                <div className="SfJXc8">
                                  <span>
                                    {formatDate(order.createdAt) || "Date"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="q4l-J2">
                              <div className="YrA9Sn">
                                <div
                                  className={
                                    order.isCancelled
                                      ? "SfJXc8O"
                                      : "SfJXc8O-incomplete"
                                  }
                                >
                                  <span style={{ color: "#0D354F" }}>
                                    Canceled
                                  </span>
                                </div>
                                <div className="GS3O1E T7AO1y sDEgvV"></div>
                                <div className="SfJXc8">
                                  <span>
                                    {formatDate(order.cancellationDate) || null}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-xl-3 col-sm-12 col-md-3 d-flex flex-column justify-content-center align-items-end pe-3 sm-margin">
                        <p
                          className="my-2"
                          style={{
                            color: "#001524",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Your item canceled.
                        </p>
                        <div className="d-flex flex-column align-items-end cancel-times">
                          {/* <div>
  <p>{order.cancellationDate || "Cancellation Date"}</p>
</div>
<div>
  <p>{order.cancellationTime || "Cancellation Time"}</p>
</div> */}
                          <div>
                            <p>
                              Reason:
                              {order.cancellationReason ||
                                "Cancellation Reason"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Container>
            </section>
            <div>
              <Modal
                open={openrating}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="d-flex justify-content-between align-items-center box-title">
                    <div>
                      <h3>Rate & Review</h3>
                    </div>
                    <div>
                      <IoMdCloseCircleOutline
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={handleCloseRating}
                      />
                    </div>
                  </div>
                  <div className="rating-box p-3">
                    <h4>Rate This Product:</h4>
                    <Rating
                      name="simple-controlled"
                      onChange={handleRatingChange}
                    />
                    <h4>Review This Product:</h4>
                    <textarea
                      rows={4}
                      onChange={(e) =>
                        setRatingData({
                          ...ratingData,
                          comment: e.target.value,
                        })
                      }
                    ></textarea>
                    <div className="text-center py-3">
                      <Button variant="contained" onClick={handlepostRating}>
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </>
        ) : (
          <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spin size="large" className="custom-spin" />
        </div>
        )}
      </div>
    </>
  );
};

export default Orders;
