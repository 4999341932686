import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Allcarditems from "../Allcarditems";
import { Dropdown, Space, Pagination, Spin } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API_URL } from "../Config";

const AllChicken = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [sortedProducts, setSortedProducts] = useState<any[]>(products);
  const [sortOrder, setSortOrder] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResult, setTotalResult] = useState<number>(0);

  const pageSize = 12;

  const getAllProduct = async (page: number) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/product?category=chicken&page=${page}&limit=${pageSize}&isDeleted=false`
      );
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setTotalResult(response.data.totalResults);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllProduct(currentPage);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage]);

  useEffect(() => {
    let sorted = [...products];
    if (sortOrder === "2") {
      sorted.sort((a, b) => a.price - b.price);
    } else if (sortOrder === "3") {
      sorted.sort((a, b) => b.price - a.price);
    }
    setSortedProducts(sorted);
  }, [products, sortOrder]);

  const handleSort = (key: string) => {
    setSortOrder(key);
  };

  return (
    <>
      <Helmet>
        <title>
          Fresh Chicken Online - Best Price & Quality Guaranteed | Order Now
        </title>
        <meta
          name="description"
          content="Buy fresh chicken online at unbeatable prices. Guaranteed quality, hygienic packing, and fast delivery to your home. Order now for delicious meals!"
        />
      </Helmet>
      <section className="py-4">
        <Container>
          <p className="nav-all pb-3">
            <Link to="/">Home</Link> {">"} <span>Chicken</span>
          </p>
          <div className="d-flex justify-content-between align-items-center allitems-nav mb-3">
            <h1>
              Chicken <span>{totalResult} Varieties</span>
            </h1>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "2",
                    label: "Low To High",
                    onClick: () => handleSort("2"),
                  },
                  {
                    key: "3",
                    label: "High To Low",
                    onClick: () => handleSort("3"),
                  },
                ],
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Sort By <i className="fi fi-sr-settings-sliders"></i>
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="row">
            {sortedProducts.length ? (
              sortedProducts.map((product) => (
                <Allcarditems key={product._id} product={product} />
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <Spin size="large" className="custom-spin" />
              </div>
            )}
          </div>
          {/* Pagination Component */}
          <div className="d-flex justify-content-center mt-4">
            <Pagination
              current={currentPage}
              total={totalPages * pageSize}
              pageSize={pageSize}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default AllChicken;
