import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CardItem from "../CardItem";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Config";
import { Spin } from "antd";

const Chicken = () => {
  const [products, setProducts] = useState<any[]>([]);

  const getAllProduct = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/product?category=chicken&page=${1}&limit=${6}&isDeleted=false`
      );

      setProducts(response.data.products);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const navigate = useNavigate();
  const handleNavChicken = () => {
    navigate("/chicken");
  };

  useEffect(() => {
    getAllProduct();
  }, []);
  return (
    <>
      <section className="py-4" style={{ borderBottom: "2px solid #0D354F" }}>
        <Container>
          <h1 className="title2 py-3 text-center">
            Buy <span>Chicken</span> Meat Online
          </h1>
          <div className="row">
            <div className="col-lg-3 sm-chickenbanner">
              <div>
                <img
                  className="img-fluid"
                  src="/assets/chickenposter.webp"
                  loading="lazy"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-lg-9">
              <div>
                <div className="row">
                  {products.length ? (
                    products.map((product) => (
                      <CardItem key={product._id} product={product} />
                    ))
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "70vh" }}
                    >
                      <Spin size="large" className="custom-spin" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center pb-3 pt-4">
            <Button
              variant="contained"
              className="see-btn"
              onClick={handleNavChicken}
            >
              See More
            </Button>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Chicken;
