import { Container } from "react-bootstrap";

const Nutritionalvalue = () => {
  return (
    <>
      <section className="value-bg">
        <Container>
          <div className="row">
            <div className="col-lg-5" style={{ marginTop: "80px" }}>
              <div>
                <img
                  className="img-fluid "
                  loading="lazy"
                  alt="model"
                  src="/assets/mm model.webp"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row py-2">
                <div className="col-lg-12 col-md-12 col-sm-12 nuti-box">
                  <div>
                    <h2 className="mb-4">
                      Nutritional Values{" "}
                      <span>
                        of Different{" "}
                        <span className="nutriMutton">Chicken</span> Parts (
                        100g )
                      </span>
                    </h2>
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick2"
                            src="/assets/c1.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Chicken Breast</h6>
                          <p>165 Calories</p>
                          <p>31g Protein</p>
                          <p>80% Protein</p>
                          <p>20% Fat</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick"
                            src="/assets/c2.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Wings</h6>
                          <p>203 Calories</p>
                          <p>30.5g Protein</p>
                          <p>64% Protein</p>
                          <p>36% Fat</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick"
                            src="/assets/c3.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Drumstick</h6>
                          <p>172 Calories</p>
                          <p>28.3g Protein</p>
                          <p>70% Protein</p>
                          <p>30% Fat</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick"
                            src="/assets/c4.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Thigh</h6>
                          <p>209 Calories</p>
                          <p>26g Protein</p>
                          <p>53% Protein</p>
                          <p>47% Fat</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2">
                <div className="col-lg-12 col-md-12 col-sm-12 nuti-box">
                  <div>
                    <h2 className="mb-4">
                      Nutritional Values{" "}
                      <span>
                        of Different <span className="nutriMutton">Mutton</span>{" "}
                        Parts ( 100g )
                      </span>
                    </h2>
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick"
                            src="/assets/m1.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Mutton leg</h6>
                          <p>168 Calories</p>
                          <p>20.3g Protein</p>
                          <p>80% Protein</p>
                          <p>9.2g Fat</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick"
                            src="/assets/m2.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Ribs</h6>
                          <p>215 Calories</p>
                          <p>20.4g Protein</p>
                          <p>64% Protein</p>
                          <p>15.7g Fat</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick"
                            src="/assets/m3.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Mutton Breast</h6>
                          <p>244 Calories</p>
                          <p>18.8g Protein</p>
                          <p>70% Protein</p>
                          <p>19.4g Fat</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-3">
                        <div>
                          <img
                            className="img-fluid chick"
                            src="/assets/m4.webp"
                            loading="lazy"
                            alt="img"
                          />
                          <h6>Loin</h6>
                          <p>203 Calories</p>
                          <p>19.9g Protein</p>
                          <p>53% Protein</p>
                          <p>14.4g Fat</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <h2 className="text-center title2">
            Why Choose <span>Meat Market</span>?
          </h2>
          <div className="row text-center d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <p className="para">
                  We ensure excellence at every step to set the highest
                  standards in taste, quality, and food safety. With our online chicken delivery.
                </p>
              </div>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-lg-3 col-md-3 col-sm-12 md-size">
              <div className="text-center p-2 md-size">
                <img
                  className="img-fluid py-2"
                  src="/assets/p1.webp"
                  width={"50px"}
                  loading="lazy"
                  alt="img"
                />
                <h5>100% Natural</h5>
                <p>
                  Our livestock is sourced directly from local farms where
                  cattle are raised in hygienic.We provide fresh online chicken delivery.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="text-center p-2 md-size">
                <img
                  className="img-fluid py-2"
                  src="/assets/p2.webp"
                  width={"50px"}
                  loading="lazy"
                  alt="img"
                />
                <h5>Premium Quality Meat</h5>
                <p>
                  Our experienced meat professionals oversee every step of the
                  cutting process to provide premium quality cuts and meat.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="text-center p-2 md-size">
                <img
                  className="img-fluid py-2"
                  src="/assets/p3.webp"
                  width={"50px"}
                  loading="lazy"
                  alt="img"
                />
                <h5>Packed with Care</h5>
                <p>
                  Each order is packed in a hygienic, temperature-controlled
                  environment, ensuring optimal freshness upon delivery.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="text-center p-2 md-size">
                <img
                  className="img-fluid py-2"
                  src="/assets/p4.webp"
                  width={"50px"}
                  loading="lazy"
                  alt="img"
                />
                <h5>Delivered Fresh</h5>
                <p>
                Online Chicken Delivery at Meat Market.After cutting, meats are frozen to retain
                  its freshness & are delivered within 24 hour.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Nutritionalvalue;
