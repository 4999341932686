import { Button } from "@mui/material";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReferalBanner = () => {
  return (
    <>
      <section className="py-4">
        <Container>
          <h1 className="title2 text-center pb-3">How Its Work</h1>
          <div className="row">
            <div className="col-lg-4 col-md-4  col-sm-6 col-6">
              <div className="d-flex">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    src="/assets/g1.webp"
                    width={"50px"}
                    loading="lazy"
                    alt="img"
                  />
                </div>
                <div className="nut-con">
                  <span>Step 1</span>
                  <p>Select your Products</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              <div className="d-flex">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    src="/assets//g2.webp"
                    width={"50px"}
                    loading="lazy"
                    alt="img"
                  />
                </div>
                <div className="nut-con">
                  <span>Step 2</span>
                  <p>Select your delivery date</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              <div className="d-flex">
                <div className="me-2">
                  <img
                    className="img-fluid"
                    src="/assets//g3.webp"
                    width={"50px"}
                    loading="lazy"
                    alt="img"
                  />
                </div>
                <div className="nut-con">
                  <span>Step 3</span>
                  <p>Delivered fresh straight to your door</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-4" style={{ overflowX: "hidden" }}>
        <div className="">
          <div className="referBox">
            <div className="row mx-0">
              <div className="col-lg-5 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-center">
                <div>
                  <div
                    className=" position-relative"
                    style={{ width: "330px" }}
                  >
                    <img
                      style={{ width: "330px" }}
                      className="img-fluid"
                      src="/assets/coinbg.webp"
                      loading="lazy"
                      alt="refer banner"
                    />
                    <img
                      src="/assets/m-coin.gif"
                      style={{ width: "177px" }}
                      className="img-fluid coin"
                      loading="lazy"
                      alt="coin"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 col-12 d-flex justify-content-start align-items-center">
                <div>
                  <div className="referbox-content py-3">
                    <h6>Shop More, Earn More !</h6>
                    <p>
                      Get <span>1 Coin</span> for Every <span>₹100</span> Spent!
                    </p>
                    <p>
                      Accumulate coins and Redeem Rewards –{" "}
                      <strong>Start Shopping Now!</strong>"
                    </p>
                    <Link to="/chicken">
                      <Button variant="contained">Shop now</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferalBanner;
