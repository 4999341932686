import { Container } from "react-bootstrap";

const About = () => {
  return (
    <>
      <section className="py-3">
        <Container>
          <h1>About Us:</h1>
          <h3>Welcome to Meat Market!</h3>
          <p>
            At Meat Market, we are passionate about bringing you the finest
            quality chicken and mutton, delivered fresh right to your doorstep.
            Based in Chennai, our mission is to make premium meat products
            accessible to everyone in the city, ensuring convenience, quality,
            and exceptional service.
          </p>
          <h3>Our Story</h3>
          <p>
            Founded with a commitment to quality and customer satisfaction, Meat
            Market was born out of a desire to provide Chennai residents with a
            reliable source of high-quality, fresh meat. Our team is dedicated
            to sourcing the best cuts from trusted suppliers, ensuring that
            every product we deliver meets the highest standards.
          </p>
          <h3>What We Offer</h3>
          <ul>
            <li>
              Premium Quality Meat: We offer a wide range of chicken and mutton
              products, carefully selected and prepared to meet your culinary
              needs. Whether you're planning a family dinner, a special
              occasion, or simply stocking up, our products are sure to meet
              your expectations.
            </li>
            <li>
              Convenient Delivery: We are proud to offer delivery services
              across all of Chennai. From the bustling city center to the
              quieter suburbs, our efficient delivery team ensures that your
              order arrives fresh and on time.
            </li>
            <li>
              Exceptional Service: Our friendly customer service team is here to
              assist you with any questions or concerns. We strive to make your
              shopping experience smooth and enjoyable, from placing your order
              to receiving your delivery.
            </li>
          </ul>
          <h3>Our Promise</h3>
          <p>
            At Meat Market, we prioritize freshness, quality, and customer
            satisfaction. Every step of our process is designed to ensure that
            you receive the best possible product. From careful sourcing to
            meticulous packaging and prompt delivery, we are dedicated to
            exceeding your expectations.
          </p>
          <h3>Why Choose Us?</h3>
          <ul>
            <li>
              Wide Coverage: We deliver all over Chennai, ensuring that everyone
              can enjoy high-quality meat products without leaving their home.
            </li>
            <li>
              Freshness Guaranteed: Our products are handled with the utmost
              care to maintain their freshness and flavor.
            </li>
            <li>
              Customer-Centric: We value your feedback and are always looking
              for ways to improve our services.
            </li>
          </ul>
          <p>
            Thank you for choosing Meat Market. We look forward to serving you
            and being a part of your culinary journey.
          </p>
        </Container>
      </section>
    </>
  );
};

export default About;
